import '../../Styles/home.scss';
import HeaderDescription from "../../Components/HeaderDescription/headerDescription";
import PageDescription from "../../Components/PageDescription/pageDescription";
import HomePageThumbnails from "../../Components/HomePageThumbnails/homePageThumbnails";
import monolitheStudio from '../../Assets/partenaires/monolitheStudio.png';
import tourismeNancy from '../../Assets/partenaires/tourismeNancy.png';
import villeNancy from '../../Assets/partenaires/villeNancy.png';
import nancy1901 from '../../Assets/aventures/nancy1901.png';


const mainTitle = (
    <>
        Rififi et course-poursuite<br />
        dans les villages de la Saint-Nicolas !
    </>
);
const mainLink = ("commencer l'aventure").toUpperCase();
const h2Title = ("partez à la découverte des trésors de nancy !").toUpperCase();
const homedescription = (
    <>
        Connaissez vous la suite très officieuse de la fameuse légende de Saint-Nicolas ?<br/>
        Arpentez la ville à travers un parcours ludique, didactique et gourmand, afin de la découvrir.<br/>
        Mais surtout, suivez pas-à-pas les trois petits enfants et découvrez comment ils ont su éviter les pièges<br/>
        tendus par le méchant boucher et par son comparse, le maléfique Père Fouettard !
    </>
)

const autresAventures = ("découvrez d'autres aventures").toUpperCase();

const partenaires = [
        {
        title: 'Monolithe studio',
        img: monolitheStudio,
        link: 'https://www.monolithe.studio/',
        },
        {
        title: 'ville de nancy',
        img: tourismeNancy,
        link: 'https://www.nancy.fr/accueil',
        },
        {
        title: 'office de tourisme de nancy',
        img: villeNancy,
        link: 'https://www.nancy-tourisme.fr/',
        }
    ]

const aventures =[
    {
        title: 'Nancy 1901',
        img: nancy1901,
        link: 'https://immersion-nancyartnouveau.com',
    }
]


export default function Home(){
    return (
        <div className="home">
            <div className="header-description">
                <HeaderDescription title={mainTitle} link={mainLink} />
            </div>

            <div className="hommePage-description">
                <PageDescription title={h2Title} description={homedescription} />
            </div>

            <div className="homePage-partenaires">
                <HomePageThumbnails title='PARTENAIRES' thumbnails={partenaires} />
            </div>

            <div className="homePage-aventures">
                <HomePageThumbnails title={autresAventures} thumbnails={aventures} />
            </div>


        </div>
    )
}