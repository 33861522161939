import '../../Styles/parcours.scss';
import ParcoursDescription from "../../Components/ParcoursDescription/parcoursDescription";
import React, {useContext} from "react";
import AudioReader from "../../Components/AudioReader/audioReader";
import petitsEnfants from "../../Assets/audio/petitsEnfants.mp3";
import Saint_Nicolas from '../../Assets/dessins/Saint_Nicolas.png';
import enfants from '../../Assets/enfants.png';
import etoileFilante from '../../Assets/etoileFilante.png';
import ane from '../../Assets/ane.png';
import Carousel from "../../Components/Carousel/carousel";
import AudioPlayer from "../../Components/AudioPlayer/audioPlayer";
import {MapContainer, TileLayer, Marker, Popup, Tooltip, Rectangle} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {EtapeContext} from "../../Contexts/EtapeContext";
import {useNavigate} from "react-router-dom";


export default function Parcours () {

    const navigate = useNavigate();

    const {etapes, setEtapeCourante, etapeCourante} = useContext(EtapeContext);

    const parcoursTitle = (
        <>
            Rififi et course-poursuite<br />
            dans les villages de la Saint-Nicolas !
        </>
    );

    const intro= (
        <>
            “Il était trois petits enfants qui s’en allaient glâner aux champs...”<br/>
            <br/>
            Vous connaissez certainement la légende du Bon Saint-Nicolas, dans laquelle le Patron de la
            Lorraine<br/>
            sauva trois petits enfants des griffes de l’horrible boucher.<br/>
            Mais... Avez-vous déjà eu la curiosité de connaitre la suite de cette incroyable histoire ?
        </>
    )

    const eventTitle=("les principaux événements").toUpperCase();

    return (
        <div className="parcours">
            {/*Header with audio player*/}
            <div className="parcours-header">
                <h1 className="parcours-header-title">{parcoursTitle}</h1>
                <AudioReader track={petitsEnfants} color={'#8A7446'}/>
                {/*<AudioPlayer element={petitsEnfants} />*/}
            </div>

            {/*Event description*/}
            <div className="histoire-st-Nicolas">
                <img src={Saint_Nicolas} alt="Saint Nicolas" className="img-Saint-Nicolas"/>
                <ParcoursDescription text={intro} link={"/"}/>
            </div>

            {/*Interactive card*/}
            <div className="plans-des-villages">
                <h2 className="plans-des-villages-title">LE PLAN DES VILLAGES</h2>
                <div className="plan-des-villages-description-images">
                    <p className="plan-des-villages-description">Suivez la trace des trois petits enfants à travers les
                        huit villages et hameaux gourmands disséminés dans les rues de Nancy. À chaque étape, des
                        surprises vous attendent. À vous de jouer !
                    </p>
                    <div className="plan-des-villages-images">
                        <img src={etoileFilante} alt=" étoile filante" className="plan-des-villages-image1"/>
                        <img src={enfants} alt="3 petis enfants" className="plan-des-villages-image2"/>
                    </div>
                </div>

                {/*interactive card emplacement*/}
                <div className="plan-des-villages-carte">
                    <MapContainer center={[48.692054, 6.184417]} zoom={14} scrollWheelZoom={true} style={{
                        width: '100%',
                        height: '100%',
                    }} whenReady={(e) => {
                        e.target.on('popupopen', function (e) {
                            setEtapeCourante(e.popup.options.id);
                            navigate("/etape");
                        });
                    }}>

                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://api.mapbox.com/styles/v1/agoraa/clv6kgwzh00mf01qpc2zy0x6x/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiYWdvcmFhIiwiYSI6ImNsNjJib3AzbTBpb24zZXAza2N6a2NnY3AifQ.I_fHOn6Gw7qb99CZkiNWKw"
                        />

                        {
                            etapes.map((etape) => {
                                return (
                                    <Marker position={etape.coords} icon={L.icon({
                                        iconUrl: etape.type === "village" ? "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png" : "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png",
                                        iconSize: [30, 30],
                                        iconAnchor: [15, 30],
                                        popupAnchor: [0, -30],
                                    })}>
                                        <Popup id={etape.id}>
                                            {etape.titre}
                                        </Popup>
                                        <Tooltip>
                                            {etape.titre}
                                        </Tooltip>
                                    </Marker>
                                )
                            })
                        }

                    </MapContainer>
                </div>
            </div>

            {/*Principal events*/}
            <div className="principaux-evenement">
                <div className="principaux-evenements-title">
                    <img src={ane} alt="âne du Saint Nicolas"/>
                    <h2 className="evenements-title">{eventTitle}</h2>
                </div>

                <div className="principaux-evenement-carousel">
                    <Carousel/>
                </div>



            </div>

        </div>
    )
}