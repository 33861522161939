import {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {EtapeContext} from "../../Contexts/EtapeContext";
import '../../Styles/navbar.scss';

export default function Navbar({}) {

    const navigate = useNavigate();

    const [listeEtapes, setListeEtapes] = useState(false);

    const {etapes, setEtapeCourante, etapeCourante} = useContext(EtapeContext);

    const location = useLocation();

    const [actualPage, setActualPage] = useState("");

    useEffect(() => {
        setActualPage(location.pathname);
        setListeEtapes(false);
        window.scrollTo(0, 0);
    }, [location.pathname]);


    return (
        <div className="navbar">
            <div className="navbar-left" onClick={() => {
                actualPage === "/etape" ? navigate("/parcours") : navigate("/")
            }}>
                { (actualPage === "/parcours" || actualPage === "/etape") &&
                <div className="navbar-left-arrow">
                    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 1L2 7L8 13" stroke="black" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                </div>
                }
                <div className="navbar-left-text">
                    {
                        (actualPage === "/") ? "Istor.io" :
                        (actualPage === "/parcours") ? "Rififi et course-poursuite dans les villages de la Saint-Nicolas !" :
                        (actualPage === "/etape") ? etapes.find((e) => {
                            return e.id === etapeCourante
                        })?.titre : ""
                    }
                </div>
            </div>
            {
                actualPage === "/etape" &&
                <div className="navbar-right">
                    { listeEtapes &&
                    <div className="liste-etapes">
                        {
                            etapes.map((etape) => {
                                return <div className="liste-etapes-etape" onClick={() => {
                                    setEtapeCourante(etape.id);
                                    setListeEtapes(false);
                                }}>
                                        <div className="liste-etapes-etape-texte">
                                            {etape?.titre}
                                        </div>
                                    </div>
                            })
                        }
                    </div>
                    }
                    <div onClick={() => {
                        setListeEtapes(!listeEtapes)
                    }} className="navbar-right-btn">
                        <div className="etape-selectionee">
                            <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16 8.5C16 12.5434 14.0258 15.024 8.5002 20.5836C3.40898 15.4548 1 12.5248 1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5Z"
                                    stroke="black" stroke-width="2"/>
                                <circle cx="8.5" cy="8.5" r="3.5" stroke="black" stroke-width="2"/>
                            </svg>
                            <div className="etape-selectionee-texte">
                                {etapes.find((e) => {
                                    return e.id === etapeCourante
                                })?.titre}
                            </div>
                        </div>
                        <div className="navbar-right-arrow">
                            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L13 1" stroke="black" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}