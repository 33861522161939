import {Link} from "react-router-dom";
import '../../Styles/headerDescription.scss';


export default function HeaderDescription({title, link}) {

    return (
        <div className="header-description-component">
            <h2 className="title-description-component">{title}</h2>

            <Link to="/parcours" className="link-description-component">{link}</Link>
        </div>
    )
}