import '../../Styles/carousel.scss';
import {
    useEffect,
    useState
} from "react";
import axios from "axios";

export default function Carousel() {
    const [activeStep, setActiveStep] =useState(0);
    const [actualites, setActualites] = useState([]);
    const [visibleIndex, setVisibleIndex] = useState(null)
    const itemsPerPage = 5;
    const maxSteps = Math.ceil(actualites.length / itemsPerPage);

    const formatDateModale = (dateString) => {
        const options = { day: '2-digit', month: 'long', year: 'numeric' };
        const formattedDateModale = new Date(dateString).toLocaleDateString('fr-FR', options);
        return formattedDateModale;
    };

    /**
     * We change the active step to the next one
     */
    const handleNext = () => {
        setActiveStep((prevActiveStep) =>
            (prevActiveStep + 1) % maxSteps // Loop back to start when we reach the end
        );
    };


    /**
     * We change the active step to the previous one
     */
    const handleBack = () => {
        setActiveStep((prevActiveStep) =>
            (prevActiveStep - 1 + maxSteps) % maxSteps // Loop to the end if we go back from the start
        );
    };

    const handleVisibilityChange = (index) => {
        setVisibleIndex(visibleIndex === index ? null : index);
    }

    /**
     * retrieves all events and set them in the state
     * @returns {Promise<void>}
     */
    const handleEvent = async() =>{
        try {
            const response = await axios.get('http://localhost:4000/actualite/all');
            setActualites(response.data)
        } catch (error) {
            console.log("Erreur lors de la récupération des données ", error);
        }
    }

    useEffect(() => {
        handleEvent()
    }, [])

    /**
     * Function to extract day and month from the date string
     * @param dateString
     * @returns {{month: string, day: number}}
     */
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getUTCDate();  // Get the day (1-31)
        const month = date.toLocaleString('default', { month: 'short' }); // Get month as abbreviated string (Jan, Feb, etc.)
        return { day, month };
    };


    /**
     * Calculate which actualities to display based on activeStep
     * @returns {*[]}
     */
    const getVisibleActualites = () => {
        const startIndex = activeStep * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return actualites.slice(startIndex, endIndex);
    };

    return(
        <div className="ActualityCarousel">
            {/* Overlay */}
            {visibleIndex !== null && (
                <div
                    className="overlay"
                    onClick={() => setVisibleIndex(null)} // Ferme la description si on clique sur l'overlay
                ></div>
            )}

            <div className="event-card-button">
                <button onClick={handleBack} >&#x2039;</button>
            </div>

            {getVisibleActualites().map((actualite, index) => {
                    const { day, month } = formatDate(actualite.date);
                    console.log(`description de l'actualité : ${actualite.image}`)

                    return (
                        <div className="actuality-card" onClick={() => handleVisibilityChange(index)} key={index} >
                            <img className="event-img" src={actualite.image ?
                                `http://localhost:4000/uploads/${actualite.image}` :
                                "https://picsum.photos/id/1005/400/300"} alt="Card 1"/>
                            <div className="event-description-date">
                                <div className="event-title">
                                    <h2 className="titre-event">{actualite.title}</h2>
                                    <p className="auteur-event">{actualite.organisator}</p>
                                </div>

                                <div className="event-date">
                                    <p className="mois-event">{month.toUpperCase()}</p>
                                    <p className="jour-event" style={{color: 'black'}}>{day}</p>
                                </div>
                            </div>

                            <div className="description-actualite" style={{
                                display: visibleIndex ===
                                index ?
                                    'block' :
                                    'none'
                            }}>
                                <h5>{actualite.title}</h5>
                                <p style={{color: 'red', marginTop:'1%', fontSize:'1rem'}}>{formatDateModale(actualite.date)}</p>
                                <p>{actualite.description}</p>
                            </div>
                        </div>
                    )
                }
            )}

            <div className="event-card-button">
                <button onClick={handleNext}>&#x203A;</button>
            </div>

        </div>
    )
}