import '../../Styles/etape.scss';
import React, {useContext} from "react";
import AudioReader from "../../Components/AudioReader/audioReader";
import petitsEnfants from "../../Assets/audio/petitsEnfants.mp3";
import ParcoursDescription from "../../Components/ParcoursDescription/parcoursDescription";
import {EtapeContext} from "../../Contexts/EtapeContext";


export default function Etape() {
    const parcoursTitle = (
        <>
            Rififi et course-poursuite<br />
            dans les villages de la Saint-Nicolas !
        </>
    );

    const intro= (
        <>
            “Il était trois petits enfants qui s’en allaient glâner aux champs...”<br/>
            <br/>
            Vous connaissez certainement la légende du Bon Saint-Nicolas, dans laquelle le Patron de la
            Lorraine<br/>
            sauva trois petits enfants des griffes de l’horrible boucher.<br/>
            Mais... Avez-vous déjà eu la curiosité de connaitre la suite de cette incroyable histoire ?
        </>
    )

    const {etapes, etapeCourante} = useContext(EtapeContext);



    return(
        <div className="etape">
            {/*Header with audio player*/}
            <div className="etape-header">
                <h1 className="etape-header-title">{parcoursTitle}</h1>
                <AudioReader track={petitsEnfants} color={"red"}/>
            </div>

            {/*Event description*/}
            <div className="histoire-st-Nicolas">
                {/*<img src='' alt="" className="img-Saint-Nicolas"/>*/}
                <ParcoursDescription text={etapes.find((e) => {
                    return e.id === etapeCourante
                })?.contenu} link={"/"}/>
            </div>
        </div>
    )
}