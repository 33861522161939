import '../../Styles/parcoursDescription.scss';
import {Link} from "react-router-dom";


export default function ParcoursDescription({text, link}) {

    return(
        <div className="parcours-description-component">
            <textPath className="parcours-description-text">{text}</textPath>
            {/*<div className="parcours-description-link">*/}
            {/*    <Link to={link} className="parcours-description-link-button" >lire la suite</Link>*/}
            {/*</div>*/}

        </div>
    )

}
