import {createContext, useState} from "react";

export const EtapeContext = createContext();

export function EtapeContextProvider({children}) {

    const etapes = [{
        id: "1",
        titre: "Grand village de Saint-Nicolas",
        contenu: "Contenu de l'étape 1",
        coords: [48.6889974, 6.1819316],
        adresse: "Place Charles III, 54100 Nancy, France",
        type: "village",
    }, {
        id: "2",
        titre: "Village de la Grande roue et de la Patinoire",
        contenu: "Contenu de l'étape 2",
        coords: [48.695716857910156, 6.182108402252197],
        adresse: "Place de la Carrière, 54000 Nancy, France",
        type: "village",
    }, {
        id: "3",
        titre: "Village Le Bois des 3 Petits Enfants",
        contenu: <>C’est un soir d’hiver magique à Nancy. Les ocons tombent doucement, transformant la ville en
            un véritable pays des merveilles. Sur la place Simone Veil, trois petits enfants s’émerveillent
            devant les vitrines scintillantes et le doux tintement des clochettes du carrousel. Leurs rires
            résonnent, remplis de l’innocence des fêtes. <br/><br/>
            Mais dans l’ombre de la gare, une silhouette se dessine. Vêtu de noir et le visage barbouillé de
            suie… c’est le Père Fouettard ! Il a pris un train spécial, directement du royaume des légendes, et
            il n’a qu’un but : attraper ces coquins d’enfants.<br/><br/>
            Mais voilà que, dans sa précipitation, il glisse sur les pavés gelés de la place de la gare. Après
            une chorégraphie digne des plus grands patineurs, il nit sur les fesses. « Argh ! » grogne-t-il,
            tandis que les enfants, hilares, courent vers le manège pour se cacher. Le Père Fouettard n’est
            peut-être pas aussi erayant qu’il veut bien le faire croire, surtout quand il éternue si fort que les
            passants éclatent de rire. Ici, au cœur de la ville, l’esprit de la Saint Nicolas triomphe toujours,
            même des plus sombres légendes.<br/><br/>
            Pendant que le Père Fouettard reprend ses esprits, vous avez le temps de courir savourer un
            chocolat chaud à l’Excelsior et laissez cette douce chaleur vous envelopper, comme un câlin
            hivernal.</>,
        coords: [48.6898834, 6.1752639],
        adresse: "Place Simone Veil, 54100 Nancy, France",
        type: "village",
    }, {
        id: "4",
        titre: "Le Hameau gourmand de Saint-Nicolas",
        contenu: "Contenu de l'étape 4",
        coords: [48.6942403, 6.1821477],
        adresse: "Place Vaudémont, 54100 Nancy, France",
        type: "hameau",
    }, {
        id: "5",
        titre: "Le Hameau de la Craffe",
        contenu: "Contenu de l'étape 5",
        coords: [48.6989009, 6.1777722],
        adresse: "Porte de la Craffe, Grande Rue, 54100 Nancy, France",
        type: "hameau",
    }, {
        id: "6",
        titre: "Le Hameau des Bons Vivants",
        contenu: "Contenu de l'étape 6",
        coords: [48.6964074, 6.1792289],
        adresse: "Place Saint-Epvre, 54100 Nancy, France",
        type: "hameau",
    }, {
        id: "7",
        titre: "Le Hameau gourmand des Vosges",
        contenu: "Contenu de l'étape 7",
        coords: [48.6852112, 6.186819],
        adresse: "Place des Vosges, 54100 Nancy, France",
        type: "hameau",
    }, {
        id: "8",
        titre: "Le petit Hameau de Nico et Moujat",
        contenu: "Contenu de l'étape 8",
        coords: [48.6912025, 6.1839515],
        adresse: "Rue du Pont Mouja, 54100 Nancy, France",
        type: "hameau",
    }];

    const [etapeCourante, setEtapeCourante] = useState(etapes[0].id);


    return (
        <EtapeContext.Provider value={{etapes, etapeCourante, setEtapeCourante}}>
            {children}
        </EtapeContext.Provider>
    );
};