import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import Home from "./Pages/Home";
import Header from "./Components/Header/header";
import Parcours from "./Pages/Parcours";
import Etape from "./Pages/Etapes";
import {EtapeContextProvider} from "./Contexts/EtapeContext";
import Navbar from "./Components/Navbar/Navbar";
import "./Styles/index.css";


const FontStyles = createGlobalStyle `
    @import url(http://fonts.googleapis.com/earlyaccess/jejuhallasan.css);
    @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <EtapeContextProvider>
            <FontStyles />
            <Router>
                <Navbar />

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/parcours" element={<Parcours />} />
                    <Route path="/etape" element={<Etape />} />
                </Routes>
            </Router>
        </EtapeContextProvider>
    </React.StrictMode>

)
