import {Link} from "react-router-dom";
import '../../Styles/homePageThumbnails.scss';


export default function HomePageThumbnails ({title, thumbnails}) {

    return (
        <div className="thumbnailsContainer">
            <h2 className="thumbnailsContainer-title">{title}</h2>

            <div className="thumbnailsContainer-links">
                {thumbnails.map((thumbnail, index) => (
                    <Link to={thumbnail.link} key={index} target="_blank" className="thumbnailsContainer-links-thumbnails">
                        <img key={index} src={thumbnail.img} alt={thumbnail.title}/>
                    </Link>
                ))}
            </div>
        </div>
    )
}