import '../../Styles/audioReader.scss';
import play from '../../Assets/icons/play.png';
import React, {
    useEffect,
    useRef,
    useState
} from "react";

export default function AudioReader({track, color}){
    const audioRef = useRef(null); // Ref for the audio element
    const [isPlaying, setIsPlaying] = useState(false); // Track play state
    const [duration, setDuration] = useState(0); // Audio duration in seconds
    const [currentTime, setCurrentTime] = useState(0); // Current playback time


    /**
     * to launch the audio
     */
    const handlePlayPause = () => {
        const audio = audioRef.current;
        if (audio) {
            if (isPlaying) {
                audio.pause();
            } else {
                audio.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    /**
     * update duration
     */
    useEffect(() => {
        const audio = audioRef.current;
        if (audio) {
            const updateDuration = () => setDuration(audio.duration);
            const updateProgress = () => {
                setCurrentTime(audio.currentTime);
                const progress = (audio.currentTime / audio.duration) * 100 || 0;
                const input = document.getElementById('piste')
                input.style.background = `linear-gradient(
                    to right,
                    ${color} 0%,
                    ${color} ${progress}%,
                    #ccc ${progress}%,
                    #ccc 100%
                )`;
            };

            audio.addEventListener("loadedmetadata", updateDuration);
            audio.addEventListener("timeupdate", updateProgress);

            return () => {
                audio.removeEventListener("loadedmetadata", updateDuration);
                audio.removeEventListener("timeupdate", updateProgress);
            };
        }
    }, []);

    /**
     * time format in mm:ss
     * @param time
     * @returns {`${number}:${string}`}
     */
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    /**
     * Seek audio
      */
    const handleSeek = (e) => {
        const audio = audioRef.current;
        if (audio) {
            audio.currentTime = e.target.value;
            setCurrentTime(audio.currentTime);
        }
    };

    /**
     * Update current time
     */
    useEffect(() => {
        const audio = audioRef.current;
        if (audio) {
            const updateCurrentTime = () => setCurrentTime(audio.currentTime);
            audio.addEventListener("timeupdate", updateCurrentTime);

            // Cleanup event listener
            return () => audio.removeEventListener("timeupdate", updateCurrentTime);
        }
    }, []);

    return (
        <div className="audio-reader">
            {track && (
                <>
                    <div className="player">
                        <img
                            src={play}
                            alt={isPlaying ? "Pause" : "Play"}
                            onClick={handlePlayPause}
                            style={{ cursor: "pointer" }}
                        />
                        <input
                            type="range"
                            id="piste"
                            min="0"
                            max={duration || 0}
                            value={currentTime}
                            onChange={handleSeek}
                        />
                        <div className="time-track">
                            <span id="elapsed">{formatTime(currentTime)}</span>
                            <span id="duration">{formatTime(duration)}</span>
                        </div>
                    </div>

                    <div>
                        <audio ref={audioRef} src={track} />
                    </div>
                </>
            )}
        </div>
    );
}